.orderHistoryPage {
	height: calc(100% - 100px);

	.orderHistory {
		height: 100%;
		overflow: auto;
		padding: 0 24px;

		.emptyOrderList {
			padding-top: 50px;
			text-align: center;
			min-height: calc(100% - 180px);
			font-size: 16px;
		}

		.loadMoreBtn {
			width: 150px;
			height: 30px;
			text-align: center;
			background-color: #fff;
			border: 1px solid #dedede;
			border-radius: 4px;
			margin: 0 auto;
			line-height: 30px;
			cursor: pointer;
			font-size: 15px;
		}

		.orderList {
			.orderItem {
				padding: 8px 0 24px;
				position: relative;
				cursor: pointer;
				margin-bottom: 16px;
				background-color: #fff;
				font-size: 12px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.10);

				.top {
					display: flex;

					.left {
						flex: 1;

						.merchantName {
							font-size: 14px;
							line-height: 19px;
							color: #333;
							font-weight: 600;
							letter-spacing: -0.28px;
							margin-bottom: 4px;
						}

						.itemInfo {
							color: #828282;
							line-height: 16px;
						}

						.orderStatus {
							font-weight: 600;
						}
					}

					.btn {
						padding: 0 10px;
						height: 34px;
						line-height: 34px;
						font-size: 14px;
						font-weight: 600;
						letter-spacing: -0.28px;
						color: var(--color-btn-text);
						background: var(--theme-color);
						border-radius: 20px;
					}
				}

				.bottom {
					color: #565656;
					line-height: 22px;
				}

				.rateBtn {
					margin-top: 4px;
				}

				.itemNames {
					line-height: 16px;
					margin-top: 4px;
					max-height: 32px;
					display: -webkit-box;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 2;
					line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}
		}

		.bottomLoading {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 40px;
			opacity: 0;
			font-size: 14px;

			[data-loader=circle] {
				width: 14px;
				height: 14px;
				margin-right: 8px;
				border: 2px solid rgba(0, 0, 0, 0.4);
				border-top-color: transparent;
				-webkit-animation: circle infinite .75s linear;
				-moz-animation: circle infinite .75s linear;
				-o-animation: circle infinite .75s linear;
				animation: circle infinite .75s linear;
				border-radius: 100%
			}

			@keyframes circle {
				0% {
					-webkit-transform: rotate(0);
					-ms-transform: rotate(0);
					-o-transform: rotate(0);
					transform: rotate(0)
				}

				100% {
					-webkit-transform: rotate(360deg);
					-ms-transform: rotate(360deg);
					-o-transform: rotate(360deg);
					transform: rotate(360deg)
				}
			}

			&.show {
				opacity: 1;
			}
		}

		.footer {
			height: 60px;
		}
	}

	.wait {
		color: var(--warn-color)  !important;
	}

	.success {
		color: var(--success-color)  !important;
	}

	.error {
		color: var(--error-color)  !important;
	}

	.mainColor {
		color: var(--theme-color);
	}

	.payAgain {
		position: fixed;
		z-index: 9;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: #fff;

		.desc {
			padding-left: 20px;
			line-height: 60px;
			font-size: 14px;
			color: #565656;

			.colorMain {
				color: var(--theme-color);
			}
		}

		.bottomBtnCon {
			background-color: rgba(255, 255, 255, 0.5);
			padding-top: 10px;

			.bottomBtn {
				width: 80%;
				background-color: var(--theme-color);
				color: #fff;
				height: 36px;
				border-radius: 18px;
				line-height: 36px;
				margin: 0 auto;
				text-align: center;
				font-size: 15px;
			}
		}
	}

	.cardBx {
		padding: 20px 20px 0;

		.cardTitle {
			font-size: 16px;
			font-weight: bold;
			line-height: 50px;
		}

		.cardContent {
			font-size: 14px;

			.sumContentLine {
				border-top: 1px solid #F2F2F2;
				height: 32px;
				display: flex;
				justify-content: space-between;
				padding: 0 12px;
				align-items: center;

				.sumContentKey {
					margin-right: 12px;
				}

				&.sumTotalBx {
					font-weight: bold;
				}
			}
		}
	}
}

.detailWrap {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0);
	transition: all 0.3s;
	z-index: 63;

	.detailPanel {
		width: 100%;
		height: 100%;
		background-color: #f2f2f2;
		transform: translate3d(0, 100%, 0);
		transition: all 0.3s;

		.panelHeader {
			height: 48px;
			line-height: 48px;
			display: flex;
			background-color: #fff;
			border-bottom: 1px solid #f2f2f2;

			.headerLeft {
				width: 40px;
				height: 48px;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;

				i {
					margin-left: 4px;
					font-size: 16px;
				}
			}

			.headerMid {
				flex: 1;
				font-size: 17px;
				font-weight: 600;
				color: #000;
				text-align: center;
			}

			.headerRight {
				width: 40px;
				height: 48px;
				position: relative;

				.closeBtn {
					left: -6px;
					top: 12px;
					position: absolute;
					width: 34px;
					height: 34px;
					cursor: pointer;
					border-radius: 50%;
					box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
					background-color: #fff;
					text-align: center;
					display: flex;
					align-items: center;
					justify-content: center;

					i {
						font-size: 16px;
						color: #333;
					}
				}
			}
		}

		.panelCon {
			max-height: calc(100% - 60px);
			overflow: auto;
		}
	}

	&.show {
		background-color: rgba(0, 0, 0, 0.3);

		.detailPanel {
			transform: translate3d(0, 0, 0);
		}
	}
}

@media only screen and (min-width: 481px) {
	.orderHistoryPage {
		height: 100%;
		padding: 30px 0;

		.orderHistory {
			padding: 24px 12px;

			.orderList {
				background-color: transparent;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-content: flex-start;
				min-height: calc(100% - 104px);

				.orderItem {
					width: calc(50% - 40px);
					max-width: 342px;
					background-color: #fff;
					margin-bottom: 40px;
					border-bottom: none;
					padding-bottom: 0;

					&::after {
						display: none;
					}
				}
			}

		}
	}

	.detailWrap {
		padding-top: 50px;

		.detailPanel {
			width: 96%;
			max-width: 1080px;
			height: calc(100% - 50px);
			margin: 0 auto;

			.panelHeader {
				height: 56px;
				line-height: 56px;

				.headerMid {
					text-align: left;
					padding-left: 16px;
				}
			}
		}

		&.show {
			background-color: rgba(0, 0, 0, 0.65);
		}
	}
}