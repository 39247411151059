.addressForm {
	transition: opacity 0.12s linear;

	&.inLoading {
		opacity: 0.4;
	}

	input {
		width: 100%;
		background-color: #F2F2F2;
		padding: 0 10px;
		border-radius: 5px;
		height: 32px;
		line-height: 32px;
		margin-top: 5px;
		border: none;
	}

	.row {
		display: flex;
		justify-content: space-between;
		padding-top: 2px;

		input {
			width: calc(50% - 4px);
		}
	}

	.addressErrTip {
		font-size: 12px;
		color: #F44336;
		line-height: 20px;
		height: 20px;
	}

	.saveAddress {
		display: flex;
		align-items: center;
		height: 20px;
		font-size: 14px;
		margin-top: 5px;

		.checkbox {
			color: var(--theme-color);
			font-size: 20px;
			cursor: pointer;
			margin-top: 1px;
		}

		span {
			color: #333;
			padding-left: 5px;
			cursor: pointer;
		}
	}

	.bigbtn {
		background-color: var(--theme-color);
		color: var(--color-btn-text);
		height: 36px;
		line-height: 36px;
		text-align: center;
		border-radius: 18px;
		margin-top: 20px;
		font-size: 16px;
		font-weight: 600;
		cursor: pointer;
		transition: all 0.25s;

		&:hover {
			box-shadow: 0 2px 8px rgba(0, 0, 0, 0.22);
		}

		&.grey {
			background-color: #e0e0e0;
			color: #fff;
		}
	}

	.loginTip {
		text-align: center;
		color: var(--theme-text-color);
		font-size: 14px;
		line-height: 20px;
		margin-top: 12px;
		cursor: pointer;

		span {
			text-decoration: underline;
		}

		&:hover {
			color: var(--theme-color);
		}
	}
}

.UserAddressOptions {
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	overflow: auto;
	position: fixed;
	z-index: 103;
	background-color: #fff;
	padding: 10px 4px;

	.tip {
		padding: 0px 10px 10px;
		line-height: 16px;
		color: var(--theme-text-color);
		font-size: 14px;
	}

	.options {
		.option {
			min-height: 28px;
			font-size: 13px;
			line-height: 16px;
			display: flex;
			align-items: center;
			padding: 5px 16px 5px 10px;
			cursor: pointer;
			border-top: 1px solid rgba(150, 150, 150, 0.1);

			img {
				opacity: 0.7;
			}

			span {
				padding-left: 10px;
				color: #343434;
			}
		}
	}
}

@media screen and (min-height:680px) {
	.addressForm .bigbtn {
		margin-top: 25px;
		height: 40px;
		line-height: 40px;
		border-radius: 20px;
	}
}

@media screen and (min-height:820px) {
	.addressForm .bigbtn {
		margin-top: 30px;
		height: 44px;
		line-height: 44px;
		font-size: 17px;
	}
}