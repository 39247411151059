@import "../../constants/global.scss";

.allergyNote {
    display: flex;
    flex-direction: column;
    font-size: 14px;
}

.allergyLabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 0 16px;
    height: 40px;
    background: #f3f3f3;
    margin-bottom: 12px;
    cursor: pointer;
}

.inBagPanel {
    flex-direction: row;
    margin-top: 6px;

    .allergyLabel {
        flex: 1 1 50%;
        overflow: hidden;
        height: 40px;
    }

    .allergyLabel:nth-child(1) {
        margin-right: 10px;
    }
}

@media screen and (max-width: 768px) {
    .inBagPanel {
        .allergyLabel {
            margin-bottom: 0px;
        }
    }
}

.haveRequest {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.add {
    font-size: 18px;
    color: #000;
}

.selectAllergensOutter {
    @include floatPanel;
    z-index: 62;
}

.selectAllergensInner {
    @include floatPanelInner;
    z-index: 62;
    height: 360px;
    width: 480px;
    top: 50%;
    left: 50%;
    margin-left: -240px;
    margin-top: -210px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.closeHeader {
    display: block;
    height: 50px;
}

.selectAllergensHeader {
    align-items: center;
    background: #f2f1f0;
    display: none;
}

.selectAllergensTitle {
    flex: 1;
    text-align: center;
    line-height: 30px;
    padding: 20px 30px 20px 0;
}

.backIcon {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    i {
        font-size: 16px;
        margin-left: 22px;
    }
}

.allergensList {
    margin: 0;
    padding: 0 16px;
    flex: 1;
    overflow: auto;

    li {
        position: relative;
        float: left;
        width: 50%;
        display: flex;
        line-height: 20px;
        padding: 15px 0;
        border-bottom: 1px solid #F2F2F2;
        cursor: pointer;
    }

    .addNote {
        width: 100%;
        display: flex;
        line-height: 20px;
        margin-top: 16px;
        padding: 15px 16px;
        background: #f2f1f0;
    }
}

.allergenIcon {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-right: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.selectedIcon {
    position: absolute;
    left: -3px;
    top: 12px;
    width: 24px;
    height: 24px;
    background-image: url(../../images/allergenIcon/delete.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    display: none;
}

.showSelected {
    display: block;
}

.Egg {
    background-image: url(../../images/allergenIcon/egg.png);
}

.Dairy {
    background-image: url(../../images/allergenIcon/dairy.png);
}

.Peanuts {
    background-image: url(../../images/allergenIcon/peanuts.png);
}

.TreeNuts {
    background-image: url(../../images/allergenIcon/treeNuts.png);
}

.Wheat {
    background-image: url(../../images/allergenIcon/wheat.png);
}

.Soy {
    background-image: url(../../images/allergenIcon/soy.png);
}

.Fish {
    background-image: url(../../images/allergenIcon/fish.png);
}

.Shellfish {
    background-image: url(../../images/allergenIcon/shellfish.png);
}

.edit {
    background: url(../../images/addNote.png) center center no-repeat;
    background-size: 100%;
}

.selectAllergensFoot {
    margin: 20px 35px;
}

/*note*/
.noteOuter {
    @include floatPanel;
    z-index: 62;
}

.noteInner {
    @include floatPanelInner;
    z-index: 62;
    width: 480px;
    height: 300px;
    padding: 20px 24px;
    left: 50%;
    top: 50%;
    margin-left: -240px;
    margin-top: -210px;
    border-radius: 5px 5px 0 0;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.noteCont {
    flex: 1;
}

.noteFoot {
    margin-top: 10px;
}

@media (max-width:480px) {
    .selectAllergensInner {
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: auto;
        margin-top: 0;
        margin-left: 0;
        border-radius: 0;
    }

    .selectAllergensInner .closeHeader {
        display: none;
    }

    .noteInner .closeHeader {
        height: 28px;
    }

    .selectAllergensHeader {
        display: flex;
        height: 48px;
    }

    .allergensList {
        li {
            width: 100%;
        }
    }

    .noteInner {
        width: 100%;
        height: auto;
        max-height: 80%;
        left: 0;
        right: 0;
        bottom: 0;
        top: auto;
        margin-top: 0;
        margin-left: 0;
    }
}