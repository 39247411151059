@import "../../constants/global.scss";

.customPromptOuter {
    @include floatPanel;
    z-index: 99;
}

.customPromptInner {
    @include floatPanelInner;
    width: 100%;
    margin: 0 auto 0;
    position: absolute;
    top: 10%;
    min-height: 40px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1366px;
    border-radius: 6px;
    font-size: 14px;
    text-align: center;
    background: rgba(255, 114, 108, 0.94);
    color: white;
}

.customPromptContent {
    padding: 0 30px;
    font-size: 14px;
    position: relative;
    top: 5%;
    margin: 0 5%;
    height: 90%;
}

.iconAdd {
    position: absolute;
    right: 10px;
    top: 0;
    transform: rotate(45deg);
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .customPromptInner {
        width: 98%;
        left: 2%;
    }
}

@media only screen and (min-width: 769px) {
    .customPromptInner {
        width: 60%;
        left: 20%;
    }
}