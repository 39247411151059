.checkBx {
    display: flex;
    position: relative;
    line-height: 24px;
    cursor: pointer;
}

.checkBx input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkMark {
    margin-right: 8px;
}

.radioLabel {
    font-size: 16px;
}

.disabledBx {
    cursor: not-allowed;

    .checkMark {
        color: #e0e0e0;
    }

    .radioLabel {
        color: #bdbdbd;
    }
}