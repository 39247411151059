@import "../../constants/global.scss";

.customInput {
	@include myInput;
	caret-color: #FFB600;

	&::placeholder {
		color: rgba(0, 0, 0, 0.2);
	}

	&:hover {
		border: 2px solid rgba(0, 0, 0, 0.8);
	}

	&:read-only {
		border: 2px solid transparent;
	}

	&.errorCustomInput {
		border: 2px solid rgba(244, 67, 54, 1);
		background-color: #FFF5F5;
	}
}

.center {
	text-align: center;
}

.customInput::-ms-reveal {
	display: none;
}

.borderInput {
	border: 1px solid var(--border-color);
}

.withClear {
	padding-right: 38px;
}

i.clearInput,
i.visible {
	width: 1px;
	height: 1px;
	position: relative;
	font-size: 16px;
	color: #333333;
}

i.clearInput::before,
i.visible::before {
	position: absolute;
	left: -40px;
	top: 50%;
	transform: translate(0, -50%);
	font-size: 24px;
	cursor: pointer;
}

i.visible {
	font-weight: 400;
}