.birthdayPickerComponent {
    .birthdayPickerComponentCon {
        position: relative;
        font-size: 14px;
        border-radius: 5px;
        border: 1px solid #ccc;
        overflow: hidden;

        .birthdayPickerBottom {
            display: flex;
            height: 44px;
            align-items: center;

            >input {
                width: 0;
                flex: 1;
                border: none;
                outline: none;
                border-radius: 4px;
                padding-left: 10px;
                letter-spacing: 1px;
                height: 32px;
                line-height: 32px;
                font-size: 16px;
            }

            .birthdayPickerRight {
                width: 100px;
                height: 44px;
                background-color: #fff8e6;
                display: flex;
                align-items: center;
                justify-content: center;
                border-left: 1px solid #f0ebe0;

                span {
                    padding-right: 5px;
                    color: #3d3014;
                }

                i {
                    font-size: 18px;
                    color: #a6571f;
                }
            }
        }

        input#birthday_picker {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 100px;
            cursor: pointer;
            border: none;
            outline: none;
            opacity: 0;
        }
    }

    .birthdayDateErr {
        color: red;
        font-size: 13px;
        line-height: 16px;
        margin-top: 5px;
    }

    .birthdaySelectBtn {
        font-size: 18px;
        border-radius: 5px;
        border: 1px solid #ccc;
        height: 44px;
        padding-left: 12px;
        padding-right: 12px;
        display: flex;
        align-items: center;

        span {
            flex: 1;
        }

        img {
            width: 20px;
            transform: rotate(90deg);
            opacity: 0.6;
        }

        &.placeholder {
            color: #676767;
            font-size: 16px;
        }
    }
}