.customSelectBx {
    width: 36px;
    height: 18px;
    border: 1px solid var(--theme-color);
    border-radius: 7px;
    background: var(--theme-color);
    background-size: 20px;
}

select {
    overflow-y: auto;
    font-size: 12px !important;
    text-align: center;
    padding: 0 7px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 0;
    text-overflow: '';
    color: var(--white-background-color);
}

select:focus {
    outline: none
}

select:disabled {
    background: var(--theme-opacity-color5);
    border-color: var(--theme-opacity-color5);
}

@media only screen and (max-width: 480px) {
    select {
        font-size: 16px;
    }
}