@import "../../constants/global.scss";

.customPromptOuter {
    @include floatPanel;
    z-index: 102;
}

.customPromptInner {
    @include floatPanelInner;
    z-index: 60;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
}

@media only screen and (min-width: 481px) {
    .customPromptInner {
        @include floatPanelInner;
        z-index: 60;
        left: 20%;
        right: 20%;
        top: 20%;
        bottom: 20%;
    }
}

@media only screen and (min-width: 1367px) {
    .customPromptInner {
        @include floatPanelInner;
        z-index: 60;
        left: calc(50% - 300px);
        width: 600px;
        top: 20%;
        bottom: 20%;
    }
}

.customPromptContent {
    font-size: 18px;
    position: relative;
    top: 5%;
    margin: 0 5%;
    height: 90%;
}

.promptContentBx,
.promptContentLowBx {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.promptContentBx {
    height: 100%;
}

.promptContentDetail {
    width: 100%;
}

.promptContentLowBx {
    height: calc(100% - 40px);
}

.EventHandlerBtn {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 50px;
}

.approveBtn {
    width: 40%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 16px;
    background-color: var(--theme-color);
    text-align: center;
    border-radius: 10px;
    color: var(--color-btn-text);
    cursor: pointer;
}

.approveBgWhite {
    background-color: var(--white-background-color);
    border: 1px solid var(--border-color);
    color: #555;
}

.promptTitle {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 5%;
    text-align: center;
}

.promptText {
    font-size: 18px;
    text-align: center;
    word-break: break-word;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardType {
    display: inline-block;
    text-align: center;
    width: 53px;
    height: 32px;
    line-height: 32px;
    background: lightgray;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid var(--border-color);
}

.promptContent {
    display: inline-block;
    margin-left: 2%;
    color: rgb(80, 80, 80);
}

.expireDate {
    margin-left: 20px;
}