.floatPanelHeader {
  width: 100%;
  height: 32px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;

  &::after {
    clear: both;
  }
}

.closeBtn {
  width: 32px;
  height: 32px;
  margin-top: 16px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  cursor: pointer;

  i {
    font-size: 32px;
    color: rgba(0, 0, 0, 0.8);
  }

}

@media screen and (max-width: 480px) {
  .leftBtnMobile {
    position: relative;
    width: 32px;

    .closeBtn {
      float: left;
      width: auto;
      height: auto;
    }

    &::after {
      clear: both;
    }
  }
}

@media screen and (min-width: 481px) {
  .floatRightBtn {
    position: relative;

    .closeBtn {
      float: right;
      width: auto;
      height: auto;
    }

    &::after {
      clear: both;
    }
  }
}