.appLinkWrap {
    position: fixed;
    z-index: 20;
    top: 60px;
    right: 10px;
    width: 250px;
    border-radius: 10px;
    border: 1px solid #e9ebf0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #F8F8F8;

    .appLinkInner {
        text-align: center;
        padding: 35px 10px 18px;

        .mealkeywayIcon {
            width: 200px;
        }

        .appLinkTitle {
            font-size: 16px;
            font-weight: 600;
            color: #000000;
            padding: 0 12px;
            line-height: 24px;
            margin: 8px 0;
        }

        .qrCodeBox {
            background-color: #fff;
            width: 200px;
            height: 200px;
            margin: 0 auto;
            border-radius: 10px;
            box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);

            .qrCode {
                width: 200px;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .appLinkDesc {
            background-color: #FFB600;
            border-radius: 5px;
            width: 150px;
            font-weight: 500;
            color: #000;
            font-size: 14px;
            line-height: 18px;
            padding: 4px 10px;
            margin: -12px auto 0;
        }
    }

    .closeBtn {
        position: absolute;
        top: -5px;
        right: -3px;
        width: 46px;
        height: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        i {
            font-size: 22px;
            transform: rotate(45deg);
        }
    }
}

.appLinkWrapMobile {
    position: fixed;
    z-index: 110;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(2.5px);

    .appLinkInner {
        position: absolute;
        z-index: 111;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px 20px 30px;

        .iphoneCon {
            width: 128px;
            height: 260px;
            position: relative;

            .cover {
                position: absolute;
                top: 3px;
                left: 3px;
                bottom: 3px;
                right: 3px;
                z-index: 111;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 24px;
            }

            .iphone {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 128px;
                height: 260px;
                z-index: 112;
            }

            .infos {
                position: absolute;
                top: 0;
                left: 0;
                width: 256px;
                height: 520px;
                z-index: 113;
                padding: 50px 24px;
                transform-origin: 0% 0%;
                transform: scale(0.5);
                display: flex;
                flex-direction: column;

                .name {
                    color: #fff;
                    font-size: 12px;
                    text-shadow: 0 0 8px rgba(0, 0, 0, 0.7);
                    padding: 10px 0;
                    line-height: 30px;
                    font-size: 24px;
                    font-weight: bold;
                }

                .location {
                    color: #fff;
                    font-size: 16px;
                    text-shadow: 0 0 5px rgba(0, 0, 0, 0.55);
                }

                .orderBtn {
                    font-size: 18px;
                    text-align: center;
                    width: 180px;
                    height: 34px;
                    line-height: 34px;
                    background-color: #FFB600;
                    border-radius: 20px;
                    color: #333;
                    margin: 0 auto;
                }
            }
        }

        .getAppBtn {
            background-color: #FFB600;
            color: #120136;
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            line-height: 46px;
            margin: 20px auto 0;
            border-radius: 24px;
            width: 82%;
            max-width: 320px;
        }

        .closeBtn {
            position: absolute;
            z-index: 112;
            top: -4px;
            right: 0;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            i {
                font-size: 24px;
                transform: rotate(45deg);
                color: #fff;
            }
        }

        .appLinkDescCon {
            position: absolute;
            right: 28px;
            bottom: 125px;
            z-index: 115;
            width: 236px;

            .appLinkDescShadow {
                position: absolute;
                top: 10px;
                left: 10px;
                right: 10px;
                bottom: 10px;
                box-shadow: -7px 0 30px rgba(0, 0, 0, 0.4);
                z-index: 116;
            }

            .appLinkDescBg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;
                border-radius: 5px;
                overflow: hidden;
                z-index: 117;

                >div {
                    flex: 1;
                    background-color: #FFFCF5;
                }

                >img {
                    height: 70px;
                    width: 236px;
                }
            }

            .appLinkDesc {
                text-align: center;
                font-family: Noto Sans;
                font-size: 22px;
                font-weight: 600;
                line-height: 24px;
                color: #333;
                width: 236px;
                padding: 15px 10px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 118;
            }

            .logo1 {
                position: absolute;
                top: -75px;
                right: 25px;
                background-color: #fff;
                width: 68px;
                height: 68px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 52px;
                    height: 52px;
                    border-radius: 5px;
                }
            }
        }
    }

    .androidSheetWrap {
        position: absolute;
        z-index: 119;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.45);

        .androidSheet {
            position: absolute;
            height: 160px;
            left: 10px;
            right: 10px;
            bottom: 0;
            background-color: #fff;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            z-index: 120;
            transform: translateY(170px);
            transition: transform 0.3s;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &.show {
                transform: translateY(0);
            }

            .loading {
                width: 30px;
                height: 30px;
                animation: circle infinite .75s linear;
                border: 3px solid #aaa;
                border-top-color: transparent;
                border-radius: 100%;
                margin: 0;
            }

            .options {
                .option {
                    margin: 15px 0;
                    width: 200px;
                    max-width: 320px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    background-color: #FFB600;
                    border-radius: 6px;
                    color: #000;
                    font-weight: 600;
                    font-size: 15px;
                }
            }
        }
    }

    @media screen and (min-width: 400px) {
        .appLinkInner {
            .appLinkDescCon {
                right: 20px;

                .logo1 {
                    right: 32px;
                }
            }
        }
    }

    @media screen and (min-width: 480px) {
        .appLinkInner {
            width: 480px;
            left: calc(50% - 240px);
            right: calc(50% - 240px);
        }
    }

    @keyframes circle {
        0% {
            transform: rotate(0)
        }

        100% {
            transform: rotate(360deg)
        }
    }
}