.panelContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 61;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    .accountName {
        margin-top: 16px;
        padding: 0 24px;
        color: rgba(0, 0, 0, 0.80);
        font-size: 28px;
        font-weight: 600;
        line-height: 36px;
    }

    .panelOptionList {
        flex: 1;
        margin-top: 24px;
        padding: 0 24px;
        overflow-y: auto;

        .title {
            margin-top: 32px;
            color: rgba(0, 0, 0, 0.35);
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
        }
    }

    .panelOption {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0 16px;
        min-height: 72px;
        line-height: 22px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: color 0.2s;

        .panelRight {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .panelRightUp {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 40px;

                .weAccept {
                    margin-right: 4px;
                    line-height: 24px;
                    color: rgba(0, 0, 0, 0.50);
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }

        .accountContentLeft {
            display: flex;
            align-items: center;
            min-height: 40px;
        }

        .left,
        .right {
            width: 24px;
            font-size: 24px;
        }

        .left {
            margin-right: 16px;
            margin-top: 1px;
        }

        .center {
            flex: 1;
            font-size: 16px;
            font-weight: 600;
        }

        .right {
            margin-left: 16px;
        }

        .title2 {
            color: rgba(0, 0, 0, 0.50);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            margin-top: 2px;
        }
    }

    &.listPanelContainer {
        width: 170px;
    }

    .backBtn {
        display: flex;
        align-items: center;
        height: 32px;
        padding: 0 24px;
        font-weight: 500;
        line-height: 24px;
        color: var(--neutral-colors-g-180, rgba(0, 0, 0, 0.80));
        cursor: pointer;

        i {
            font-size: 32px;
        }
    }

    .titleTip {
        padding: 0 24px;
        margin-top: 16px;
        margin-bottom: 32px;
        color: var(--neutral-colors-g-180, rgba(0, 0, 0, 0.80));
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    .desc {
        margin-bottom: 32px;
        color: rgba(0, 0, 0, 0.80);
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
    }

    .cardBx {
        flex: 1;
        border-bottom: none;
        display: flex;
        overflow: hidden;

        &.orderHistoryBx {
            flex: 1;
            display: flex;
            overflow: hidden;
        }

        .accountContentRight {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            .emailBx,
            .fullnameBx,
            .phonenumBx {
                margin-bottom: 16px;

                &.emailBx {
                    margin-bottom: 32px;
                }

                .accountTxtGray {
                    margin-bottom: 10px;
                    color: rgba(0, 0, 0, 0.80);
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                }

                .phoneInputBX {
                    position: relative;
                }

                .countryCode {
                    position: absolute;
                    left: 16px;
                    top: 50%;
                    transform: translate(0, -50%);
                    padding-right: 16px;
                    color: rgba(0, 0, 0, 0.80);
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 16px;
                    border-right: 2px solid rgba(0, 0, 0, 0.2);
                }

                .phoneInput {
                    width: 100%;
                    height: 56px;
                    padding: 0px 16px 0 70px;
                    line-height: 56px;
                    border: 2px solid transparent;
                    outline: none;
                    border-radius: 10px;
                    background-color: #f2f2f2;
                    font-size: 16px;
                    font-weight: 600;
                    -webkit-appearance: none;
                    caret-color: #FFB600;

                    &:hover {
                        border: 2px solid rgba(0, 0, 0, 0.8);
                    }

                    &:focus {
                        background-color: #fff;
                        border: 2px solid rgba(0, 0, 0, 0.8);
                    }

                    &::placeholder {
                        font-weight: 400;
                        color: rgba(0, 0, 0, 0.2);
                    }
                }

                i.clearInput {
                    width: 24px;
                    height: 24px;
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    font-size: 24px;
                    color: #333333;
                    cursor: pointer;
                    transform: translate(0, -50%);
                }
            }

            .phoneErrTip {
                margin-top: 8px;
                margin-bottom: 16px;
                font-size: 14px;
                line-height: 20px;
                color: red;
            }

            .addressBx .addressItemBx,
            .paymentBx .paymentItemBx {
                display: flex;
                padding: 7px 0;
                justify-content: space-between;
                border-radius: 5px;

                &:hover {
                    background-color: rgb(250, 250, 250);
                }

                .textContent {
                    flex: 1;

                    .defaultLabel {
                        font-size: 12px;
                        border: 1px solid var(--theme-color);
                        border-radius: 4px;
                        line-height: 20px;
                        padding: 0 6px;
                        margin-left: 8px;
                        color: var(--theme-text-color);
                        background-color: var(--theme-opacity-color);
                    }
                }
            }

            .upContent {
                flex: 1;
                display: flex;
            }

            .defaultLabelBx {
                margin-top: 12px;
                margin-left: 40px;

                &.paymentMrLeft {
                    margin-left: 72px;
                }

                .defaultLabel {
                    margin-top: 12px;
                    padding: 4px 8px;
                    color: #FFB600;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    border-radius: 14px;
                    background: #FFF8E5;
                }
            }

            .panelOptionBx {
                border-top: 1px solid rgba(0, 0, 0, 0.1);
            }

            .addNewButton {
                margin-top: 10px;
                width: 120px;
                height: 30px;
                background: var(--theme-color);
                color: var(--color-btn-text);
                font-size: 14px;
                line-height: 30px;
                border-radius: 10px;
                text-align: center;
                cursor: pointer;
                margin-bottom: 20px;
                font-weight: 600;
                transition: all 0.2s;

                &:hover {
                    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
                }
            }

            .submitBtn {
                margin-top: 32px;
                margin-bottom: 32px;
            }

            //支付部分开始
            .title {
                color: rgba(0, 0, 0, 0.35);
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
            }

            .paymentItemBx {
                display: flex;
                flex-direction: column;
                padding-top: 16px;
                padding-bottom: 16px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.10);
            }

            .paymentFoot {
                margin-top: 32px;
                margin-bottom: 32px;

                .paymentItemBx {
                    flex-direction: row;
                    align-items: center;
                }
            }

            .cardInfo {
                display: flex;

                .cardIcon {
                    margin-right: 8px;
                    margin-top: 7px;
                    width: 24px;
                    height: 24px;
                }

                .last4 {
                    padding-left: 4px;
                    color: rgba(0, 0, 0, 0.80);
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                }

                .expDate {
                    padding-left: 4px;
                    color: rgba(0, 0, 0, 0.50);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;

                    &.expired {
                        color: rgba(244, 67, 54, 1);
                    }

                }

                .addressLine1 {
                    color: #333;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 22px;
                }

                .addressLine2 {
                    color: rgba(0, 0, 0, 0.70);
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                }
            }

            .cardCenter {
                display: flex;
                flex-direction: column;
            }

            .checkbox {
                display: flex;
                align-items: center;
                height: auto;
                flex: 1;

                i {
                    padding: 4px;
                    width: 24px;
                    height: 24px;
                    font-size: 24px;
                    border-radius: 6px;
                }

                i::after {
                    top: 3px;
                    left: 3px;
                }

                i:hover {
                    background: rgba(0, 0, 0, 0.05);
                }

                i:active {
                    background: rgba(0, 0, 0, 0.08);
                }
            }

            .editContentBx {
                height: auto;
                display: flex;
                align-items: center;

                .editContent {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    color: #a3a6af;

                    &.deleteAddressIcon {
                        margin-left: 8px;
                    }

                    i {
                        width: 24px;
                        height: 24px;
                        padding: 4px;
                        font-size: 24px;
                        border-radius: 6px;
                        color: rgba(0, 0, 0, 0.8);
                    }

                    i:hover {
                        background: rgba(0, 0, 0, 0.05);
                    }

                    i:active {
                        background: rgba(0, 0, 0, 0.08);
                    }

                    &:hover {
                        color: var(--theme-color);
                    }
                }
            }

            .textContent {
                display: flex;
                flex: 1;
            }

            .paymentAddIcon {
                margin-right: 16px;
                font-size: 24px;
            }

            .paymentType {
                display: flex;
                flex-direction: column;
                flex: 1;
            }

            .paymentName {
                color: rgba(0, 0, 0, 0.80);
                font-size: 16px;
                font-weight: 600;
                line-height: 22px;
            }

            .backaddPaymentIcon {
                font-size: 24px;
            }

            //支付部分结束

            .languageOptionBx {
                border-top: none;

                .languageOptionRadio {
                    width: 8px;
                    height: 8px;
                    border-radius: 8px;
                    box-shadow: 0 0 0 2px #FFB600;
                    border: 3px solid #fff;
                    background-color: #fff;
                    margin-right: 9px;

                    &.active {
                        background-color: #FFB600;
                    }
                }

                .checkbox {
                    flex-direction: row-reverse;
                    justify-content: space-between;
                }
            }

            .legalOptionBx {
                border-top: none;
            }
        }

        .accountContScroll {
            flex: 1;
            overflow: auto;
            padding: 0 24px;
        }

        .accountSettingTitle {
            padding-bottom: 8px;
            margin-bottom: 16px;
            color: rgba(0, 0, 0, 0.35);
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            /* 133.333% */
        }

        // 地址部分开始 
        .editAddressCon {
            display: flex;
            flex-wrap: wrap;
            position: relative;

            .fixedIcon {
                position: fixed;
                top: 12px;
                right: 24px;
                width: 24px;
                height: 24px;
                padding: 4px;
                color: rgba(0, 0, 0, 0.8);

                i {
                    font-size: 24px;
                    color: rgba(0, 0, 0, 0.8);
                }
            }

            .addressDetail {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
            }

            input {
                padding: 0 10px;
                width: 100%;
                height: 56px;
                line-height: 56px;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.80);
                background: rgba(0, 0, 0, 0.05);
                border-radius: 10px;
                font-weight: 600;
                border: 2px solid rgba(0, 0, 0, 0);
                caret-color: #FFB600;
                outline: none;

                &::placeholder {
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.2);
                }

                &:hover {
                    border: 2px solid rgba(0, 0, 0, 0.80);
                    box-shadow: 0 0 0 1.5px transparent inset;
                }

                &:focus {
                    border: 2px solid rgba(0, 0, 0, 0.80);
                    background: #fff;
                }

                &:-webkit-autofill {
                    border: 2px solid rgba(242, 242, 242, 1);
                    box-shadow: 0 0 0 1000px rgba(242, 242, 242, 1) inset;
                }

                &:-webkit-autofill:hover {
                    border: 2px solid rgba(0, 0, 0, 0.80);
                    box-shadow: 0 0 0 1.5px rgba(242, 242, 242, 1) inset, 0 0 0 1000px rgba(242, 242, 242, 1) inset;

                }

                &:-webkit-autofill:focus {
                    border: 2px solid rgba(0, 0, 0, 0.80);
                    box-shadow: 0 0 0 1.5px rgba(242, 242, 242, 1) inset, 0 0 0 1000px #fff inset;
                }
            }

            .row {
                display: flex;
                flex-direction: column;

                &:nth-child(1) {
                    width: 100%;
                }

                &:nth-child(2),
                &:nth-child(4) {
                    width: 50%;
                    padding-right: 8px;
                }

                &:nth-child(3),
                &:nth-child(5) {
                    width: 50%;
                    padding-left: 8px;
                }

                .label {
                    margin-top: 16px;
                    margin-bottom: 8px;
                    color: rgba(0, 0, 0, 0.80);
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                }
            }

            .error_input {
                border-color: rgba(244, 67, 54, 1);
            }

            .addressErrTip {
                font-size: 14px;
                line-height: 20px;
                color: rgba(244, 67, 54, 1);
                margin-top: 8px;
            }

            .saveAddressBtn {
                width: 100%;
                margin-top: 32px;
            }
        }

        // 地址部分结束
        // 设置密码开始        
        .inputBox {
            position: relative;
            margin-top: 16px;

            &:first-child {
                margin-top: 0;
            }

            &.roll {
                transition: all 0.45s;
            }

            &.hidden {
                height: 0;
                opacity: 0;
            }
        }

        .passwordDontMatchTip {
            font-size: 14px;
            line-height: 20px;
            color: rgba(244, 67, 54, 1);
            padding-top: 8px;
        }

        .passwordTip {
            margin-top: 16px;
            margin-bottom: 16px;

            .passwordMatch {
                display: flex;
                align-items: center;
                color: var(--neutral-colors-g-180, rgba(0, 0, 0, 0.80));
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 8px;

                i {
                    font-size: 16px;
                    margin-right: 8px;
                    color: rgba(244, 67, 54, 1);
                }

                &.success {
                    i {
                        color: rgba(105, 195, 63, 1);
                    }
                }
            }
        }

        .submitPasswordBx {
            margin-top: 32px;
        }

        // 设置密码结束   
        .deleteAccount {

            .title {
                border-bottom: none;
            }

            .deleteAccountCont {
                height: 200px;
                overflow-y: auto;
                color: rgba(0, 0, 0, 0.80);
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                padding-right: 16px;
            }
        }
    }

    .secondConfirmationCon {
        padding: 24px;

        .title {
            margin-top: 0;
            padding-bottom: 24px;
            color: rgba(0, 0, 0, 0.80);
            font-size: 24px;
            font-weight: 600;
            line-height: 32px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.10);
        }

        .text {
            margin-top: 24px;
            font-size: 16px;
            font-weight: 600;
        }

        .submitBtnBox {
            margin-top: 32px;

            button:nth-child(2) {
                margin-top: 16px;
            }
        }
    }

    .verifyCodeCon {
        margin: 16px 24px 32px;
    }


    .cardIconList {
        display: flex;
    }

    .cardIcon {
        width: 24px;
        height: 24px;
        margin-right: 4px;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    .mastercard {
        background-image: url(../../images/card/mastercard.png);
    }

    .visa {
        background-image: url(../../images/card/visa.png);
    }

    .jcb {
        background-image: url(../../images/card/jcb.png);
    }

    .dinersclub {
        background-image: url(../../images/card/dinersclub.png);
    }

    .americanexpress {
        background-image: url(../../images/card/americanexpress.png);
    }

    .discover {
        background-image: url(../../images/card/discover.png);
    }

    .unionpay {
        background-image: url(../../images/card/unionpay.png);
    }
}

@media screen and (max-width:480px) {
    .panelContainer {
        overflow: hidden;
        padding-top: 12px;

        .paymentFoot {
            margin-bottom: 32px;
        }

        .panelContainerInner {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: hidden;

            .panelOptionList {
                flex: 1;
            }
        }

        .verifyCodeCon {
            width: auto;
            margin: 0;
            padding: 72px 24px 0;
        }
    }
}

@media screen and (min-width:481px) {
    .panelContainer {
        background: #F5F5F5;

        .panelContainerInner {
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 100%;
            overflow: hidden;
            max-width: 1140px;
            margin: 12px auto;
            padding: 0 69px;
            background: #fff;
            border-radius: 10px;

            .accountName {
                margin-top: 30px;
                padding: 16px 20px 0;
            }

            .titleTip {
                padding: 0;
                margin-top: 0;
            }

            .accountContScroll {
                padding: 0;
            }

            .cardBx {
                margin: 0;
                padding: 0;
                display: flex;
                align-items: center;
				cursor: default;

                .deleteAccount {
                    max-width: 342px;
                }

                .editAddressCon {
                    input {
                        height: 44px;
                        line-height: 44px;
                    }

                    .fixedIcon {
                        position: absolute;
                        right: 0;
                        padding: 0;
                        width: 32px;
                        height: 32px;

                        i {
                            width: 24px;
                            height: 24px;
                            padding: 4px;
                            font-size: 24px;
                            color: rgba(0, 0, 0, 0.8);
                            border-radius: 6px;

                            &:hover {
                                background: rgba(0, 0, 0, 0.05);
                            }

                            &:active {
                                background: rgba(0, 0, 0, 0.08);
                            }
                        }
                    }
                }
            }

            .panelRight>.cardBx {
                margin: 16px 0 0;
                padding: 0;
                display: none;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
            }

            .right {
                margin-right: 16px;
            }

            .rotate {
                transform: rotate(90deg);
                transition: all 0.5s ease-in-out;
            }

            .activate .cardBx {
                display: flex;
            }

            .activate .rotate {
                transform: rotate(-90deg);
            }

            .panelOptionList {
                flex: 1;
                padding: 0 20px;
                margin-top: 16px;
                margin-bottom: 30px;

                .panelOption {
                    position: relative;
                    padding: 8px 0 8px 16px;
                    margin: 8px 0 16px;
                    min-height: 56px;
                    border-bottom: none;
                    border-radius: 10px;

                    &:hover {
                        background: rgba(0, 0, 0, 0.03);
                    }

                    &:active {
                        background: rgba(0, 0, 0, 0.05);
                    }

                    &::before {
                        position: absolute;
                        left: 0;
                        bottom: -8px;
                        content: "";
                        width: 100%;
                        height: 1px;
                        background: rgba(0, 0, 0, 0.1);

                    }
                }

                .panelOption1.activate {
                    padding-bottom: 0;
                }

                .activate {
                    &:hover {
                        background-color: #fff;
                    }

                }

                .panelOption1 {
                    align-items: flex-start;
                }

                .accountContentLeft {
                    width: 35%;
                }

                .accountContentRight {
                    width: 100%;

                    .panelOption:last-child {
                        margin-bottom: 8px;

                        &::before {
                            background: none;
                        }
                    }

                    .paymentItemBx {
                        padding-top: 8px;
                        padding-bottom: 8px;
                    }

                    .mouseStatusCont {
                        padding: 8px 16px;
                        border-radius: 10px;

                        &:hover {
                            background: rgba(0, 0, 0, 0.03);
                        }
                    }

                    .phoneInput {
                        height: 44px;
                        line-height: 44px;
                    }

                    .submitBtn {
                        margin-bottom: 32px;
                    }

                    // 支付部分开始
                    .title {
                        margin-top: 0;
                    }

                    .accountSettingTitle {
                        margin-top: 16px;
                    }

                    .savedTitle {
                        margin-top: 16px;
                    }

                    .checkbox {
                        flex-direction: row-reverse;
                        justify-content: flex-end;

                        span {
                            flex: 1;
                        }
                    }

                    .savedListBx {
                        margin-bottom: 16px;
                    }

                    .paymentFoot {
                        margin-top: 0;
                        margin-bottom: 0;

                        .addPayment {
                            display: flex;
                            justify-content: center;
                            margin-bottom: 8px;
                        }
                    }

                    // 支付部分结束
                    .defaultLabelBx {
                        margin-left: 0;

                        &.paymentMrLeft {
                            margin-left: 32px;
                        }
                    }
                }

                .logoutBx {
                    margin-top: 32px;

                    .logOut {
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        .secondConfirmationCon {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: calc(100% - 48px);
            margin: 24px auto;
            padding: 0 24px;
            max-width: 342px;
            min-width: 390px;
        }

        .verifyCodeCon {
            width: 342px;
            height: 100%;
            margin: 0 auto;
            display: flex;
            align-items: center;
        }
    }
}