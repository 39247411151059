@mixin myInput {
    width: 100%;
    height: 40px;
    padding: 0px 18px;
    line-height: 40px;
    border: 2px solid transparent;
    outline: none;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.05);
    font-size: 16px;
    -webkit-appearance: none;
    font-weight: 600;
    font-family: "OpenSans";
    caret-color: #FFB600;

    &::placeholder {
        color: #979797;
        font-weight: 400;
    }

    &:focus {
        background-color: #fff;
        border: 2px solid rgba(0, 0, 0, 0.8);
    }

    &:read-only {
        color: rgba(0, 0, 0, 0.50);
        background-color: rgba(0, 0, 0, 0.05);
        box-shadow: none;
        border: 2px solid rgba(242, 242, 242, 1);
        cursor: default;
    }

    &:hover {
        box-shadow: 0 0 0 1.5px transparent inset;
    }

    &:-webkit-autofill {
        border: 2px solid rgba(242, 242, 242, 1);
        box-shadow: 0 0 0 1000px rgba(242, 242, 242, 1) inset;
    }

    &:-webkit-autofill:hover {
        border: 2px solid rgba(0, 0, 0, 0.80);
        box-shadow: 0 0 0 1.5px rgba(242, 242, 242, 1) inset, 0 0 0 1000px rgba(242, 242, 242, 1) inset;

    }

    &:-webkit-autofill:focus {
        border: 2px solid rgba(0, 0, 0, 0.80);
        box-shadow: 0 0 0 1.5px rgba(242, 242, 242, 1) inset, 0 0 0 1000px #fff inset;
    }
}

/*card styles*/
@mixin cardBx {
    width: 100%;
    border: 1px solid var(--border-color);
    padding: 20px;
    background-color: var(--white-background-color);
    line-height: 20px;
    margin-top: 15px;
    border: 0;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

@mixin cardBxBg {
    width: 100%;
    padding: 0.6rem;
    background-color: var(--white-background-color);
    box-shadow: 0px 2px 6px 3px rgba(226, 226, 226, 0.2);
    border-radius: 4px;
    margin-bottom: 0.6rem;
    border: none;
}

@mixin cardBxSDI {
    width: 100%;
    background: var(--white-background-color) url('../../images/card_bottom.png') 0 bottom no-repeat;
    background-size: contain;
    padding: 0.5rem 0.6rem;
    border-radius: 4px;
    margin-bottom: 0.6rem;
}

@mixin cardTitle {
    width: 60%;
    font-size: 20px;
    font-weight: bold;
    display: inline-block;
}

@mixin cardContent {
    margin-top: 10px;
    font-size: 18px;
}

/*float panel outer*/
@mixin floatPanel {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 95;
    background: rgba(0, 0, 0, 0.65);
}

@mixin floatPanelInner {
    position: absolute;
    background-color: var(--white-background-color);
    border-radius: 10px;
}

@mixin floatCenterPanelInner {
    background-color: var(--white-background-color);
    border-radius: 10px;
}