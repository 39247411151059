@import "../../constants/global.scss";

.promotionDetailsOuter {
    @include floatPanel;
    z-index: 62;
}

.promotionDetailsInner {
    @include floatPanelInner;
    z-index: 62;
    height: auto;
    padding: 20px 24px;
    max-height: 600px;
    left: 50%;
    right: unset;
    width: 90%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
}

.bindPromBg {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    background: url("../../images/celebration.png") center center no-repeat;
    background-size: 80px;
}

.promTitle {
    padding: 2px 0;
    margin: 10px 0 0;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
}

.promMain {
    flex: 1;
    overflow: auto;
}

.promCont {
    margin: 12px 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 13px;
    line-height: 24px;
}

.promFoot {
    margin: 24px 22px 6px;
}

.details {
    margin-top: 10px;
    text-align: center;

    span {
        line-height: 20px;
        text-decoration: underline;
        cursor: pointer;
        font-size: 14px;
    }
}

@media (min-width:769px) {
    .promotionDetailsInner {
        width: 460px;
        max-height: 420px;
    }

    .details {
        margin-top: 18px;
    }
}