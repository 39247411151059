.footerBx {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    flex-direction: column;
    width: 100%;
    margin-bottom: 12px;
    color: #828282;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.24px;
}

.textBlack {
    color: #999;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 10px;
}

@media (max-width:768px) {
    .footerBx {
        font-size: 0.33rem;
    }

    .textBlack {
        margin-left: 0.24rem;
    }
}